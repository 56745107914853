module.exports = [{
      plugin: require('../../../themes/paws/gatsby-browser.tsx'),
      options: {"plugins":[],"campaignId":"92d67c6a-0317-4313-a7c8-32a94786b18a","country":"IE"},
    },{
      plugin: require('../../../../node_modules/gatsby-plugin-manifest/gatsby-browser.js'),
      options: {"plugins":[],"icon":"/opt/build/repo/packages/themes/agria/src/images/icon-512x512.png","icon_options":{"purpose":"any maskable"},"name":"Agria Pet Insurance","short_name":"Agria","start_url":"/","background_color":"#02315B","theme_color":"#02315B","display":"minimal-ui","legacy":true,"theme_color_in_head":true,"cache_busting_mode":"query","crossOrigin":"anonymous","include_favicon":true,"cacheDigest":"1864b9fcc160722ec00366f136cbe20f"},
    },{
      plugin: require('../../../themes/agria/gatsby-browser.tsx'),
      options: {"plugins":[],"country":"IE","currency":"EUR"},
    },{
      plugin: require('../../../themes/qab/gatsby-browser.tsx'),
      options: {"plugins":[],"companyName":"Agria Petinsure","contactNumber":"021 202 9119","footerTerms":"<p>Capstone Financial Services Limited, trading as Agria Petinsure and Petinsure is regulated by the Central Bank of Ireland. Directors: Bernard O’Sullivan & Monica Tuvelid (Sweden). Registered in Ireland with registration number 451193.</p>\n        <p><br />Agria Petinsure policies are underwritten by Försäkringsaktiebolaget Agria (publ), c/o Agria Petinsure, PO Box 911, Little Island, Cork, Ireland T45 YR96. Försäkringsaktiebolaget Agria (publ), trading as Agria Petinsure is authorised by Finansinspektionen in Sweden and is regulated by the Central Bank of Ireland for conduct of business rules.</p>","trustpilotUrl":"https://www.trustpilot.com/review/agriapetinsure.ie","allowedSpecies":["Cat","Dog"],"petOrAnimal":"pet"},
    },{
      plugin: require('../../../../node_modules/gatsby/dist/internal-plugins/partytown/gatsby-browser.js'),
      options: {"plugins":[]},
    }]
